import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const createVerificationCodeAPI = async ({
    orgId,
    portalId,
    invitedPortalDetails,
    accessingFromControls,
    acceptInvitation,
    sessionId,
}) => {
    return await axios
        .post(
            ENDPOINT_DEV +
                `organization/${orgId}/portal/${portalId}/create-verification-code?accessingFromControls=${accessingFromControls}`,
            {
                invitedPortalDetails,
                acceptInvitation,
                sessionId,
            },
        )
        .then((res) => {
            return res;
        });
};
