import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const pollSessionStatusAPI = async ({ orgId, portalId, sessionId }) => {
    return await axios
        .get(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/session/${sessionId}/status/poll`)
        .then((res) => {
            return res;
        });
};
