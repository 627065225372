import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const scannedQRConnectionCallAPI = async (orgPortal) => {
    return await axios
        .post(
            ENDPOINT_DEV +
                `organization/${orgPortal?.orgId}/portal/${orgPortal?.portalId}/session/${orgPortal?.sessionId}/join-session`,
            {},
        )
        .then((res) => {
            return res;
        });
};
