import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { setBookingInformation } from '../redux-toolkit/reducer/localBookingReducer';

export const useAppendPIdEIdToBookingInfo = () => {
    const storedBookingInformation = useSelector((state) => state.localBooking.bookingInformation);
    const dispatch = useDispatch();

    /**
     * Decrypts and updates sessionStorage booking information json
     * @param {*} value
     * @param {'p_id' | 'e_id' | 'm_token'} type
     */
    const appendPIdEIdToBookingInfoLS = useCallback(
        (value, type) => {
            value = value && value.indexOf(' ') !== -1 ? value.replaceAll(' ', '+') : value;
            const key = process.env.REACT_APP_CRYPTO_SECRET_KEY || '';
            const decryptedToBytes = AES.decrypt(value, key);
            const decryptedValue = decryptedToBytes.toString(Utf8);

            const obj =
                type === 'p_id'
                    ? { assignedPId: decryptedValue }
                    : type === 'e_id'
                    ? { assignedEId: decryptedValue }
                    : { assignedMToken: decryptedValue };

            let localS = storedBookingInformation ? { ...storedBookingInformation } : null;

            if (localS) {
                Object.assign(localS, obj);
                dispatch(setBookingInformation({ bookingInformation: localS }));
                return localS;
            } else {
                dispatch(setBookingInformation({ bookingInformation: obj }));
                return obj;
            }
        },
        [storedBookingInformation, dispatch],
    );

    return { appendPIdEIdToBookingInfoLS };
};
