import { useEffect } from 'react';
import { resetParticipantData, setCallEnded, setCallInitiated } from '../redux-toolkit/reducer/teamsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getPortalByOrgIdPortalId } from '../redux-toolkit/reducer/portalReducer';

export default function useCmdTeamCallEndedHook({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.localPortal?.portal);

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';

            if (command === 'team-call-ended' && message?.name === portal.portal_id) {
                dispatch(setCallInitiated(false));
                dispatch(setCallEnded(true));
                dispatch(resetParticipantData());
                await dispatch(
                    getPortalByOrgIdPortalId({
                        orgId: portal?.org_id,
                        portalId: portal?.portal_id,
                    }),
                );
            }
        };

        handleCommand();
    }, [message, dispatch, portal.portal_id, portal.org_id]);
}
