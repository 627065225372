import { createSlice } from '@reduxjs/toolkit';

const initialState = { showLogo: true, showBackBtn: false, showLoginText: false, showLogoutBtn: false };

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        // this is to just manipulate state object
        setShowLogo(state, action) {
            state.showLogo = action.payload;
        },
        setShowLoginText(state, action) {
            state.showLoginText = action.payload;
        },
        setShowBackBtn(state, action) {
            state.showBackBtn = action.payload;
        },
        setShowLogoutBtn(state, action) {
            state.showLogoutBtn = action.payload;
        },
    },
});

export const { setShowLogo, setShowLoginText, setShowBackBtn, setShowLogoutBtn } = headerSlice.actions;
export default headerSlice.reducer;
