import { configureStore } from '@reduxjs/toolkit';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from '../reducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['localPortal', 'localBooking'],
    stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
    {
        reducer: persistedReducer,
        devTools: true,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: false,
            });
        },
    },
    //composeWithDevTools()
);

export const persistor = persistStore(store);
