import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const fireCommandToInitiateOrJoinBookingSessionAPI = async (obj) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${obj.orgId}/portal/${obj.portalId}/session/${obj.sessionId}/initiate`, {
            isRenderingFromDynamicUrl: obj.isRenderingFromDynamicUrl,
        })
        .then((res) => {
            return res;
        });
};
