import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const recordingConfigAPI = async (orgId, portalId, sessionId, state) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/direct/record-config`, {
            sessionId,
            state,
            isControllerUsage: true,
        })
        .then((res) => res);
};
