import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const getQRInfoFromTokenAPI = async (tokenParams) => {
    return await axios
        .get(
            ENDPOINT_DEV +
                `url/tokenize/${tokenParams.token}?flag=${tokenParams.tokenFlag}&tokenType=${tokenParams.tokenType}`,
        )
        .then((res) => {
            return res;
        });
};
