import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const controlsVolumeUpdateAblyAPI = async (orgId, portalId, sysVolume, sessionId) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/direct/volume-config-ably`, {
            sysVolume: sysVolume,
            sessionId,
            isControllerUsage: true,
        })
        .then((res) => {
            return res;
        });
};
