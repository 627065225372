import React from 'react';

export const ToastSuccessIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.93871 14.1313L14.7987 9.27129V9.26129C14.9312 9.11912 15.0033 8.93107 14.9999 8.73677C14.9965 8.54247 14.9177 8.35708 14.7803 8.21967C14.6429 8.08226 14.4575 8.00354 14.2632 8.00012C14.0689 7.99669 13.8809 8.06881 13.7387 8.20129L9.29871 12.7113L7.29871 10.7113C7.23005 10.6376 7.14725 10.5785 7.05525 10.5375C6.96325 10.4965 6.86393 10.4745 6.76323 10.4727C6.66253 10.4709 6.5625 10.4894 6.46911 10.5272C6.37572 10.5649 6.29089 10.621 6.21967 10.6923C6.14845 10.7635 6.09231 10.8483 6.05459 10.9417C6.01686 11.0351 5.99834 11.1351 6.00012 11.2358C6.00189 11.3365 6.02393 11.4358 6.06493 11.5278C6.10592 11.6198 6.16502 11.7026 6.23871 11.7713L8.59871 14.1313C8.78246 14.2971 9.02119 14.3889 9.26871 14.3889C9.51623 14.3889 9.75496 14.2971 9.93871 14.1313Z"
                fill="#4CAF50"
            />
        </svg>
    );
};
