import { createSlice } from '@reduxjs/toolkit';

const initialState = { userObj: {} };

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // this is to just manipulate state object
        setUser: (state, action) => {
            state.userObj = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
