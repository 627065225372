import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authPinVerificationAPI } from '../../api/authPinVerificationAPI';

const initialState = {
    portal: {},
    loadingValidateAuthenticationPin: false,
    validateAuthenticationPinRes: {},
    validateAuthenticationPinErr: '',
    isSecurityPinAuthenticated: false,
    securityAuthToken: null,
    authenticatedPortalId: null,
    validateAuthenticationPinErrAt: null,
};

// Verfiy Authentication PIN
export const validateAuthPin = createAsyncThunk('connection/validateAuthPin', async ({ orgPortal, authPin }) => {
    return await authPinVerificationAPI(orgPortal, { authPin });
});

const localPortalSlice = createSlice({
    name: 'localPortal',
    initialState,
    reducers: {
        setLocalPortal: (state, action) => {
            state.portal = action.payload.portal;
        },
        addInfoToSelectedPortal: (state, action) => {
            state.portal = { ...state.portal, ...action.payload };
        },
        removeInfoFromSelectedPortal: (state, action) => {
            const portal = { ...state.portal };
            action.payload.deletedKeys.forEach((key) => {
                if (portal?.[key] !== undefined) {
                    delete portal[key];
                }
            });
            state.portal = { ...portal };
        },
        resetAuthPinValidationResponse: (state) => {
            state.validateAuthenticationPinRes = {};
            state.validateAuthenticationPinErr = '';
            state.loadingValidateAuthenticationPin = false;
        },
        setPortalSecurityPinAuth: (state, action) => {
            state.isSecurityPinAuthenticated = action.payload?.isAuth;
            state.authenticatedPortalId = action.payload.portalId || null;
        },
        setPortalSecurityAuthToken: (state, action) => {
            state.securityAuthToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        // validateAuthenticationPin set response
        builder
            .addCase(validateAuthPin.pending, (state) => {
                state.loadingValidateAuthenticationPin = true;
                state.validateAuthenticationPinErr = '';
                state.isSecurityPinAuthenticated = false;
            })
            .addCase(validateAuthPin.fulfilled, (state, action) => {
                const token = action?.payload?.data?.data?.token;
                const portalId = action?.payload?.data?.data?.portal_id;
                // sessionStorage.setItem('securityToken', token);
                state.securityAuthToken = token;
                state.isSecurityPinAuthenticated = true;
                state.authenticatedPortalId = portalId;
                state.loadingValidateAuthenticationPin = false;
                state.validateAuthenticationPinRes = action?.payload;
                state.validateAuthenticationPinErr = '';
            })
            .addCase(validateAuthPin.rejected, (state, action) => {
                state.loadingValidateAuthenticationPin = false;
                state.validateAuthenticationPinErr = action.error.message;
                state.validateAuthenticationPinErrAt = Date.now();
            });
    },
});

export const {
    setLocalPortal,
    addInfoToSelectedPortal,
    removeInfoFromSelectedPortal,
    setPortalSecurityPinAuth,
    setPortalSecurityAuthToken,
    resetAuthPinValidationResponse,
} = localPortalSlice.actions;
export default localPortalSlice.reducer;
