import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const endConnectionAPI = async ({ orgId, portalId, sessionId }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/session/${sessionId}/end`, {
            isControllerUsage: true,
        })
        .then((res) => {
            return res;
        });
};
