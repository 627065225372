import { combineReducers } from '@reduxjs/toolkit';
import connectionReducer from './connectionReducer';
import headerReducer from './headerReducer';
import portalListReducer from './portalListReducer';
import portalReducer from './portalReducer';
import tokenReducer from './tokenReducer';
import userReducer from './userReducer';
import bookingConnectionReducer from './bookingConnectionReducer';
import controlsConfigReducer from './controlsConfigReducer';
import roomReducer from './roomReducer';
import guestReducer from './guestReducer';
import localPortalReducer from './localPortalReducer';
import localBookingReducer from './localBookingReducer';
import orgReducer from './orgReducer';
import ablyReducer from './ablyReducer';
import teamsReducer from './teamsReducer';

const reducer = combineReducers({
    header: headerReducer,
    portalList: portalListReducer,
    user: userReducer,
    portal: portalReducer,
    connection: connectionReducer,
    token: tokenReducer,
    bookedConnection: bookingConnectionReducer,
    controlsConfig: controlsConfigReducer,
    room: roomReducer,
    guest: guestReducer,
    localPortal: localPortalReducer,
    localBooking: localBookingReducer,
    organization: orgReducer,
    ably: ablyReducer,
    teams: teamsReducer
});

export default reducer;
