import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOrgAPI } from '../../api/getOrgAPI';
import { getFeatureFlagObj } from '../../utils';
import { getListOfGroupsAPI } from '../../api/getListOfGroupsAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {},
    featureFlags: {},
    groupList: [],
    featureLoading: false,
};

export const getOrgDetailAPI = createAsyncThunk('organization/getOrgDetailAPI', async (orgPortal) => {
    const response = await getOrgAPI(orgPortal);
    return response.data;
});

export const getListOfGroups = createAsyncThunk('organization/getListOfGroups', async (orgId) => {
    const response = await getListOfGroupsAPI(orgId);
    return response.data;
});

const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        // this is to just manipulate state object
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrgDetailAPI.pending, (state) => {
                // action is inferred correctly here if using TS
                state.featureLoading = true;
                state.error = false;
            })
            .addCase(getOrgDetailAPI.fulfilled, (state, action) => {
                state.featureLoading = false;
                state.error = false;
                if (action.payload?.featureFlags) {
                    state.featureFlags = getFeatureFlagObj(action.payload);
                }
            })
            .addCase(getOrgDetailAPI.rejected, (state, action) => {
                state.featureLoading = false;
                state.error = true;
                state.errorObj = action.error;
                state.organization = null;
            })
            .addCase(getListOfGroups.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getListOfGroups.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.groupList = action?.payload?.data;
            })
            .addCase(getListOfGroups.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.groupList = [];
            });
    },
});

// export const { setInvitePortal, setTitle, setPortalList } = organizationSlice.actions;
export default organizationSlice.reducer;
