import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const joinDeclinedInviteAPI = async ({ orgId, sessionId, inviterPortalId, inviteePortalId, callBack }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/session/${sessionId}/join-declined-invite`, {
            inviterPortalId,
            inviteePortalId,
            callBack,
        })
        .then((res) => {
            return res;
        });
};
