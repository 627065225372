import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { controlsMICAPI } from '../../api/controlsMICAPI';
import { controlsMuteUnMuteUpdateAPI, controlsMuteUnMuteUpdateAPIForAbly } from '../../api/controlsMuteUnMuteUpdateAPI';
import { controlsVolumeUpdateAPI } from '../../api/controlsVolumeUpdateAPI';
import { recordingConfigAPI } from '../../api/recordingConfigAPI';
import { playSoundAPI } from '../../api/playSoundAPI';
import { controlsVolumeUpdateAblyAPI } from '../../api/controlsVolumeUpdateAblyAPI';

const initialState = {
    controlsConfigLoading: false,
    controlsConfigError: false,
    controlsConfigErrorObj: {},
    volumeUpdated: {},
    volumeUpdatedAbly: {},
    muteUnmute: {},
    muteUnmuteForAbly: {},
    mic: {},
    recording: {},
    playSound: {},
};

export const updateControlsScreenVolume = createAsyncThunk(
    'controlsConfig/updateControlsScreenVolume',
    async ({ orgId, portalId, sysVolume, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsVolumeUpdateAPI(orgId, portalId, sysVolume, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateControlsScreenVolumeAbly = createAsyncThunk(
    'controlsConfig/updateControlsScreenVolumeAbly',
    async ({ orgId, portalId, sysVolume, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsVolumeUpdateAblyAPI(orgId, portalId, sysVolume, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateControlsMuteUnMute = createAsyncThunk(
    'controlsConfig/updateControlsMuteUnMute',
    async ({ orgId, portalId, isMuted, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsMuteUnMuteUpdateAPI(orgId, portalId, isMuted, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateControlsMuteUnMuteForAbly = createAsyncThunk(
    'controlsConfig/updateControlsMuteUnMuteForAbly',
    async ({ orgId, portalId, isMuted, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsMuteUnMuteUpdateAPIForAbly(orgId, portalId, isMuted, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateControlsMIC = createAsyncThunk(
    'controlsConfig/updateControlsMIC',
    async ({ orgId, portalId, mic, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsMICAPI(orgId, portalId, mic, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const recordingConfig = createAsyncThunk(
    'controlsConfig/recordingConfig',
    async ({ orgId, portalId, sessionId, state }, { rejectWithValue }) => {
        try {
            return await recordingConfigAPI(orgId, portalId, sessionId, state);
        } catch (err) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    },
);

export const playSoundConfig = createAsyncThunk(
    'controlsConfig/playSoundConfig',
    async ({ orgId, portalId }, { rejectWithValue }) => {
        try {
            return await playSoundAPI(orgId, portalId);
        } catch (err) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    },
);

const controlsConfigSlice = createSlice({
    name: 'controlsConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateControlsScreenVolume.pending, (state) => {
                // action is inferred correctly here if using TS
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.volumeUpdated = {};
            })
            .addCase(updateControlsScreenVolume.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.volumeUpdated = action.payload.data;
            })
            .addCase(updateControlsScreenVolume.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.volumeUpdated = {};
            })
            .addCase(updateControlsScreenVolumeAbly.pending, (state) => {
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.volumeUpdatedAbly = {};
            })
            .addCase(updateControlsScreenVolumeAbly.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.volumeUpdatedAbly = action.payload.data;
            })
            .addCase(updateControlsScreenVolumeAbly.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.volumeUpdatedAbly = {};
            })
            .addCase(updateControlsMuteUnMute.pending, (state) => {
                // action is inferred correctly here if using TS
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.muteUnmute = {};
            })
            .addCase(updateControlsMuteUnMute.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.muteUnmute = action.payload.data;
            })
            .addCase(updateControlsMuteUnMute.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.muteUnmute = {};
            })

            .addCase(updateControlsMuteUnMuteForAbly.pending, (state) => {
                // action is inferred correctly here if using TS
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.muteUnmuteForAbly = {};
            })
            .addCase(updateControlsMuteUnMuteForAbly.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.muteUnmuteForAbly = action.payload.data;
            })
            .addCase(updateControlsMuteUnMuteForAbly.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.muteUnmuteForAbly = {};
            })

            .addCase(updateControlsMIC.pending, (state) => {
                // action is inferred correctly here if using TS
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.mic = {};
            })
            .addCase(updateControlsMIC.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.mic = action.payload.data;
            })
            .addCase(updateControlsMIC.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.mic = {};
            })
            .addCase(recordingConfig.pending, (state) => {
                // action is inferred correctly here if using TS
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.recording = {};
            })
            .addCase(recordingConfig.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.recording = action.payload.data;
            })
            .addCase(recordingConfig.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.recording = {};
            })
            .addCase(playSoundConfig.pending, (state) => {
                state.controlsConfigLoading = true;
                state.controlsConfigError = false;
                state.playSound = {};
            })
            .addCase(playSoundConfig.fulfilled, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = false;
                state.playSound = action.payload.data;
            })
            .addCase(playSoundConfig.rejected, (state, action) => {
                state.controlsConfigLoading = false;
                state.controlsConfigError = true;
                state.controlsConfigErrorObj = { message: action?.payload?.message };
                state.playSound = {};
            });
    },
});
export const { setInitiateOrJoinConnection, setAllBookingObjsEmpty, setBookedConnection } = controlsConfigSlice.actions;
export default controlsConfigSlice.reducer;
