import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const checkEarlyJoinBookingSessionAPI = async ({ orgId, portalId, sessionId }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/session/${sessionId}/check-early-join/booking`)
        .then((res) => {
            return res;
        });
};
