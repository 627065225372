import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPortalByOrgIdPortalId } from '../redux-toolkit/reducer/portalReducer';

export default function useCmdEndSession({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.localPortal?.portal);
    const handleEndSession = async () => {
        await dispatch(
            getPortalByOrgIdPortalId({
                orgId: portal?.org_id,
                portalId: portal.portal_id,
            }),
        );
    };

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};
            if (command === 'end-session' && message?.name === portal.portal_id) {
                await handleEndSession(commandData);
            }
        };

        handleCommand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
