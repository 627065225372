import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const validateBookingVerificationCodeAPI = async (orgPortal, body) => {
    return await axios
        .post(
            ENDPOINT_DEV +
                `organization/${orgPortal.orgId}/portal/${orgPortal.portalId}/session/${orgPortal.sessionId}/validate-verification-code`,
            body,
        )
        .then((res) => {
            return res.data;
        });
};
