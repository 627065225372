import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCallInitiated: false,
    isConnectionError: false,
    error: '',
    isCallEnded: false,
    teamsParticipantData: [],
    portalParticipantData: [],
    isMuted: false,
    waitingScreen: false,
    msTeamsData: null,
};

const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setCallInitiated: (state, action) => {
            state.isCallInitiated = action.payload;
        },
        setConnectionError: (state, action) => {
            state.isConnectionError = action.payload;
        },
        setCallEnded: (state, action) => {
            state.isCallEnded = action.payload;
            state.waitingScreen = false;
        },
        setWaitingScreen: (state, action) => {
            state.waitingScreen = action.payload;
        },
        resetTeamState: () => initialState,
        setParticipantData: (state, action) => {
            state.teamsParticipantData = action.payload.teamsParticipantData;
            state.portalParticipantData = action.payload.portalParticipantData;
        },
        resetParticipantData: (state) => {
            state.teamsParticipantData = [];
            state.portalParticipantData = [];
        },
        setMuteStatus: (state, action) => {
            state.isMuted = action.payload;
        },
        setMsTeamsData: (state, action) => {
            state.msTeamsData = action.payload;
        },
    },
});

export const {
    setCallInitiated,
    setCallEnded,
    setConnectionError,
    resetTeamState,
    setParticipantData,
    resetParticipantData,
    setMuteStatus,
    setWaitingScreen,
    setMsTeamsData,
} = teamsSlice.actions;

export default teamsSlice.reducer;
