import { useRef } from 'react';

export const useBeforeMount = (callback) => {
    const mounted = useRef(false);

    if (!mounted.current) {
        callback();
        mounted.current = true;
    }
};
