import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const portalLeftConnectionAPI = async ({ orgId, portalId, sessionId }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/session/${sessionId}/portal-left-meeting`, {})
        .then((res) => {
            return res;
        });
};
