import React from 'react';

export const ToastErrorIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.6667 15C29.6667 23.1002 23.1002 29.6667 15 29.6667C6.89983 29.6667 0.333344 23.1002 0.333344 15C0.333344 6.89986 6.89983 0.333374 15 0.333374C23.1002 0.333374 29.6667 6.89986 29.6667 15Z"
                fill="#F44336"
            />
            <path
                d="M16.0371 12.4075C16.0371 11.8347 15.5728 11.3705 15 11.3705C14.4273 11.3705 13.963 11.8347 13.963 12.4075V14.4816C13.963 15.0543 14.4273 15.5186 15 15.5186C15.5728 15.5186 16.0371 15.0543 16.0371 14.4816V12.4075Z"
                fill="white"
            />
            <path
                d="M15 18.1112C15.5728 18.1112 16.0371 17.6469 16.0371 17.0742C16.0371 16.5014 15.5728 16.0371 15 16.0371C14.4273 16.0371 13.963 16.5014 13.963 17.0742C13.963 17.6469 14.4273 18.1112 15 18.1112Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8832 6.16028C15.6945 5.85358 15.3601 5.66675 15 5.66675C14.6399 5.66675 14.3056 5.85358 14.1168 6.16028L5.82052 19.6418C5.62362 19.9617 5.61517 20.3632 5.79844 20.6911C5.98171 21.0191 6.32802 21.2223 6.70372 21.2223H23.2963C23.672 21.2223 24.0183 21.0191 24.2016 20.6911C24.3849 20.3632 24.3764 19.9617 24.1795 19.6418L15.8832 6.16028ZM15 8.6825L21.4405 19.1482H8.55957L15 8.6825Z"
                fill="white"
            />
        </svg>
    );
};
