import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const fetchTimeStampConnectionApi = async (orgPortal) => {
    return await axios
        .get(
            ENDPOINT_DEV +
                `organization/${orgPortal.orgId}/portal/${orgPortal.portalId}/bookings` +
                (orgPortal.fromTimeStamp ? '?fromTimeStamp=' + orgPortal.fromTimeStamp : '') +
                (orgPortal.toTimeStamp ? '&toTimeStamp=' + orgPortal.toTimeStamp : ''),
            {},
        )
        .then((res) => {
            return res;
        });
};
