import * as Ably from 'ably';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ENDPOINT_DEV } from '../api/configs';

const useAblyClient = () => {
    const [ablyClient, setAblyClient] = useState(null);
    const createTokenRequest = async () => {
        const response = await axios.post(`${ENDPOINT_DEV}ably/generate-token/controller`);

        return response.data.token;
    };

    useEffect(() => {
        let client = null;
        const fetchData = async () => {
            try {
                client = new Ably.Realtime({
                    authCallback: async (tokenParams, callback) => {
                        const tokenRequest = await createTokenRequest(); // Make a network request to your server
                        callback(null, tokenRequest);
                    },
                });

                setAblyClient(client);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching token:', error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            if (client) {
                client.close();
            }
        };
    }, []);

    return ablyClient;
};

export default useAblyClient;
