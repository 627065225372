import { decode } from 'js-base64';

/**
 * converts a Base64 string to a UTF-8 string.
 * @param {string} src Base64 string.  Both normal and URL-safe are supported
 * @returns {string} UTF-8 string
 */
export const decodeAllTypeStr = (src) => {
    return decode(src);
};
