import { createContext, useContext, useEffect } from 'react';
import { usePresenceListener } from 'ably/react';
import useAblyPresenceHook from '../hooks/useAblyPresenceHook';
import useAblyPublishChannel from '../hooks/useAblyPublishChannel';
import useAblyChannelHook from '../hooks/useAblyChanel';
import { useDispatch } from 'react-redux';
import { setAblyMessage, setPresenceData } from '../redux-toolkit/reducer/ablyReducer';
import useAblyPortalPresenceHook from '../hooks/useAblyPortalPresenceHook';
import usePortalRegisterORUpdatedHook from '../hooks/usePortalRegisterORUpdatedHook';
import useCmdInvitedToSession from '../hooks/useCmdInvitedToSession';
import useCmdDeclineSession from '../hooks/useCmdDeclineSession';
import useAblyDeclineStatus from '../hooks/useAblyDeclineStatus';
import useCmdTeamInitiateCallHook from '../hooks/useCmdTeamInitiateCallHook';
import useCmdTeamCallEndedHook from '../hooks/useCmdTeamCallEndedHook';
import useCmdTeamParticipantsHook from '../hooks/useCmdTeamParticipantsHook';
import useCmdFeatureFlagUpdated from '../hooks/useCmdFeatureFlagUpdated';
import useCmdEndSession from '../hooks/useCmdEndSession';
import useCmdUpdateStatus from '../hooks/useCmdUpdateStatus';

const AblyStateContext = createContext(null);

function AblyStateProvider({ children }) {
    const { message } = useAblyChannelHook();
    const { presenceData } = usePresenceListener('portals');
    const dispatch = useDispatch();
    useAblyPresenceHook({ presenceData });
    useAblyPublishChannel();
    useAblyPortalPresenceHook({ presenceData });
    usePortalRegisterORUpdatedHook({ message });
    useCmdInvitedToSession({ message });
    useCmdDeclineSession({ message });
    useAblyDeclineStatus({ presenceData });
    useCmdTeamInitiateCallHook({ message });
    useCmdTeamCallEndedHook({ message });
    useCmdTeamParticipantsHook({ message });
    useCmdFeatureFlagUpdated({ message });
    useCmdEndSession({ message });
    useCmdUpdateStatus({ message });

    useEffect(() => {
        dispatch(setAblyMessage(message));
    }, [dispatch, message]);

    useEffect(() => {
        dispatch(setPresenceData(presenceData));
    }, [dispatch, presenceData]);
    return <AblyStateContext.Provider value={{}}>{children}</AblyStateContext.Provider>;
}

function useAblyStateContext() {
    const context = useContext(AblyStateContext);
    if (!context) {
        throw new Error('useAblyStateContext must be used within the AblyStateProvider');
    }
    return context;
}

export { AblyStateProvider, useAblyStateContext };
