import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const controlsMuteUnMuteUpdateAPI = async (orgId, portalId, isMuted, sessionId) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/direct/mute-config`, {
            isMuted: isMuted,
            sessionId,
            isControllerUsage: true,
        })
        .then((res) => {
            return res;
        });
};

export const controlsMuteUnMuteUpdateAPIForAbly = async (orgId, portalId, isMuted, sessionId) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/direct/mute-config-ably`, {
            isMuted: isMuted,
            sessionId,
            isControllerUsage: true,
        })
        .then((res) => {
            return res;
        });
};
