import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { getPortalByOrgIdPortalId, setPortal } from '../redux-toolkit/reducer/portalReducer';
import { PORTAL_OFFLINE } from '../utils/constants';
import isEqual from 'lodash/isEqual';
import { setMsTeamsData } from '../redux-toolkit/reducer/teamsReducer';

export default function useAblyPortalPresenceHook({ presenceData }) {
    const dispatch = useDispatch();
    const { portalRaw } = useSelector(({ portal }) => portal);

    const previousFilterPresenceClientIds = useRef([]);

    const filterPresenceClientIds = presenceData
        .filter((_portal) => _portal.clientId !== 'controller' && !_portal.clientId.includes('-(admin'))
        .map((_portal) => _portal.clientId);

    useEffect(() => {
        if (portalRaw && portalRaw.portal_id) {
            const presencePortal = presenceData.find((_portal) => _portal?.clientId === portalRaw.portal_id);
            const presencePortalStatus = presencePortal?.data?.data?.status || presencePortal?.data?.status;
            const data = {
                ...portalRaw,
                connectionStatus: presencePortalStatus ? presencePortalStatus : PORTAL_OFFLINE,
                msTeams: presencePortal?.data?.msTeams,
            };
            dispatch(setPortal(data));
            dispatch(setMsTeamsData(presencePortal?.data?.msTeams));
        }
    }, [portalRaw, dispatch, presenceData]);

    useEffect(() => {
        // Only call the API if filterPresenceClientIds has actually changed
        if (
            filterPresenceClientIds.length > 0 &&
            !isEqual(filterPresenceClientIds, previousFilterPresenceClientIds.current)
        ) {
            if (portalRaw && portalRaw.portal_id) {
                dispatch(
                    getPortalByOrgIdPortalId({
                        orgId: portalRaw.org_id,
                        portalId: portalRaw.portal_id,
                    }),
                );
            }
            previousFilterPresenceClientIds.current = filterPresenceClientIds;
        }
    }, [filterPresenceClientIds, portalRaw, dispatch]);

    return { presenceData };
}
