import React from 'react';
import { INV_PARTICIPANTS_TOAST_ID } from '../../utils/constants';
import { getToastIcon } from '../../utils';

const CustomToast = (props) => {
    const { msg, btnIcon, callback, type } = props;

    return (
        <div
            className={`toast-custom-container toast-${type} d-flex`}
            id={btnIcon ? INV_PARTICIPANTS_TOAST_ID : ''}
            onClick={() => !btnIcon && callback && callback()}
        >
            {btnIcon ? <></> : getToastIcon(type)}
            <div
                className={`d-flex flex-row align-items-center justify-content-between ${
                    btnIcon ? 'custom-toast-text-btn-div' : ''
                }`}
            >
                <div className="msg b1">{msg}</div>
                {btnIcon && (
                    <button className={'btn-circle btn-circle-sm2 enabled'} onClick={() => callback()}>
                        <i className={`icon-enabled ${`icon-${btnIcon}`}`}></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default CustomToast;
