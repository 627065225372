import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const updateRatingAPI = async ({ sessionId, portalId, selectedRating }) => {
    return await axios
        .post(ENDPOINT_DEV + `/analytics/session/${sessionId}/portal/${portalId}/linkUpdate`, {
            nps: selectedRating.toString(),
        })
        .then((res) => {
            return res;
        });
};
